import { API_URL, transformResponse, transformErrorResponse } from "../../utilities";
import createAPI from "./createAPI";

const punctualityDashboardApi = createAPI({
  reducerPath: "punctualityDashboardApi",
  tagTypes: ["PunctualityDashboard"], // Tags are used to refetch the data on mutation
  endpoints: (builder) => ({
    punctualityDashboard: builder.query({
      query: () => API_URL.PUNCTUALITY_DASHBOARD,
      keepUnusedDataFor: 5,
      transformResponse,
      transformErrorResponse
    })
  })
});

export const {
  usePunctualityDashboardQuery,
  punctualityDashboardReducerPath = punctualityDashboardApi.reducerPath,
  punctualityDashboardReducer = punctualityDashboardApi.reducer,
  punctualityDashboardMiddleware = punctualityDashboardApi.middleware
} = punctualityDashboardApi;