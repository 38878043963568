import { API_URL } from "../../utilities";
import createAPI from "./createAPI";

const taskApi = createAPI({
  reducerPath: "tasksApi",
  tagTypes: ["Task"], // Tags are used to refetch the data on mutation
  endpoints: (builder) => ({
    tasks: builder.query({
      query: () => API_URL.TASKS,
      providesTags: ["Task"]
    }),
    addTask: builder.mutation({
      query: (task) => ({
        url: API_URL.TASKS,
        method: "POST",
        body: task
      }),
      invalidatesTags: ["Task"]
    }),
    updateTask: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${API_URL.TASKS}/${id}`,
        method: "PUT",
        body: rest
      }),
      invalidatesTags: ["Task"]
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `${API_URL.TASKS}/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Task"]
    })
  })
});

export const {
  useTasksQuery,
  useAddTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  taskReducerPath = taskApi.reducerPath,
  taskReducer = taskApi.reducer,
  taskMiddleware = taskApi.middleware
} = taskApi;