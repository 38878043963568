export const ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_OPTIONS = [50, 100, 150];

export const MARKER_STATUS = {
    PARKED: 'parked',
    INCIDENT: 'incident',
    INAIR: 'inair',
    MISSION: 'mission',
    OFFLINE: 'offline'
}

export const MAP_TYPE = {
    ROADMAP: "roadmap",
    SATELLITE: "satellite",
    HYBRID: "hybrid",
    TERRAIN: "terrain",
};

export const ICON_NAME = {
    MENU: 'menu',
    ARROWDOWN: 'keyboard_arrow_down',
    ARROWUP: 'keyboard_arrow_up',
    DASHBOARD:'dashboard_customize',
    FILTER:'filter_alt',
    RELOAD:'refresh',
    DONLOADPDF:'picture_as_pdf',
    DOWNLOADXL:'downloading',
    SETTING:'settings'
}

export const SCREEN_TITLE = {
    DASHBOARD: 'Dashboards',
    PUNCTUALITY_DASHBOARD: 'Punctuality Dashboard',
}

export const FIELD_LABEL = {
    HIDE: 'Hide',
    TRAIN_STATUS: 'Train Status',
    TOC: 'TOC',
    HEADCODE: 'Headcode',
    SERVICE_GROUP: 'Service Group',
    PASSENGER_SERVICE: 'Passenger Service',
    LOCATION_SOURCE: 'Location Source',
    GPS_GEOFENCE: 'GPS Geofence',
    INTERVAL_TYPE: 'Interval Type',
    LAST_LOCATION: 'Last Location',
    LINE: 'Line',
    LAST_ACTIVITY: 'Last Activity',
    PUNCTUALITY: 'Punctuality',
    NEXT_LOCATION: 'Next Location',
    NEXT_ACTIVITY: 'Next Activity',
    ALERT: 'Alert',
}

export const INPUT_NAME = {}

export const ACCESSOR = {
    HIDE: 'hide',
    TRAIN_STATUS: 'train_status',
    TOC: 'toc',
    HEADCODE: 'head_code',
    SERVICE_GROUP: 'service_group',
    PASSENGER_SERVICE: 'is_passenger_service',
    LOCATION_SOURCE: 'gps_source',
    GPS_GEOFENCE: 'geo_fence',
    CURRENT_INTERVAL_TYPE: 'current_interval_type',
    LAST_LOCATION: 'last_actual_loc',
    LINE_DIRECTION: 'line_direction',
    LINE: 'line',
    LAST_ACTIVITY: 'last_activity',
    ALERT: 'alert_type',
    CODE: 'code',
    ROUTE: 'route',
    CURRENT_INTERVAL_DELAY: 'current_interval_delays',
    LAST_ACTIVITY_DELAY: 'last_actual_delay',
    COMBINED_DELAY: 'combined_delay',
    ORIGIN_TS: 'origin_ts',
}

export const VERBIAGE_LABEL = {}

export const ALERT_TYPES = {
    DWELL: 'dwell_alerts',
}