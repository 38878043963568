import parked from "../../assets/images/10-19-late.png";
import incident from "../../assets/images/shade-tooltip.png";
import inAir from "../../assets/images/10-19-late.png";
import mission from "../../assets/images/shade-tooltip.png";
import offline from "../../assets/images/10-19-late.png";
import dwellAlert from '../../assets/images/dwell.png';
import swrlogo from '../../assets/images/SWR-logo.svg'

export const IMAGE = {
    PARKED: parked,
    INCIDENT: incident,
    INAIR: inAir,
    MISSION: mission,
    OFFLINE: offline,
    DWELL_ALERT: dwellAlert,
    SWRLOGO:swrlogo,
}