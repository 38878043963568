import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer, { apiMiddlewares } from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2, // This is used to merge multi level deep or nested merge.
  blacklist: [], // With the blacklist property, we can specify which part of state not to persist.
  whitelist: [], // With the whitelist property does the opposite, specifying which part of the state to persist.
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure and export the redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(...apiMiddlewares)
})

// Export the persist store
export const persistor = persistStore(store);