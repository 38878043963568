import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';
import ErrorBoundary from './views/Misc/ErrorBoundary';
import './index.css';
import './assets/styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-icons/iconfont/outlined.css';

const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<span>Loading...</span>}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
