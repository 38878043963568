import { combineReducers } from '@reduxjs/toolkit';

// Import all individual reducers
import counterReducer from './slices/counter';

// Redux slices containing reducer and a custom middleware
import { taskReducerPath, taskReducer, taskMiddleware } from './apiSlices/task';
import { punctualityDashboardReducerPath, punctualityDashboardReducer, punctualityDashboardMiddleware } from './apiSlices/punctualityDashboard';

// Combine all individual reducer into single one and pass this into persist reducer.
const rootReducer = combineReducers({
    counter: counterReducer,

    // When the data have been fetched from our server, RTK Query will store the data in the Redux store as a cache
    [taskReducerPath]: taskReducer,
    [punctualityDashboardReducerPath]: punctualityDashboardReducer,
});

export const apiMiddlewares = [
    taskMiddleware,
    punctualityDashboardMiddleware
]

export default rootReducer;