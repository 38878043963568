import {
    createApi,
    fetchBaseQuery
} from "@reduxjs/toolkit/query/react";
import { Mutex } from 'async-mutex'; // Using async-mutex to prevent multiple calls when failing with any particular errors.

// create a new mutex
const mutex = new Mutex();

// This will work as an request interceptor
const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
        headers.set('x-api-key', process.env.REACT_APP_HEADER_AUTH_KEY)
        return headers;
    }
});

// This will work as a response interceptor
const baseQueryWithReauth = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    /**
     * THIS IS A SAMPLE ERROR RESPONSE WITH STATUS 401.
     * This is just for understanding purpose.
     * We will update/replace this code as per the need.
     */
    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                // Write the state changing code here...
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
}

const createAPI = (props = {}) => createApi({
    baseQuery: baseQueryWithReauth,
    ...props
});

export default createAPI;