import { MARKER_STATUS } from "../constants";
import { IMAGE } from "../constants/images";

export const renderMarkerIcon = (status) => {
    switch (status) {
        case MARKER_STATUS.PARKED:
            return IMAGE.PARKED;

        case MARKER_STATUS.INAIR:
            return IMAGE.INAIR;

        case MARKER_STATUS.INCIDENT:
            return IMAGE.INCIDENT;

        case MARKER_STATUS.MISSION:
            return IMAGE.MISSION;

        case MARKER_STATUS.OFFLINE:
            return IMAGE.OFFLINE;

        default:
            return '';
    }
}

export const transformResponse = (result, _meta, _arg) => {
    // Handle the success response here...
    return result;
}

export const transformErrorResponse = (response, _meta, _arg) => {
    // Handle the error response here...
    return response;
}

export const getDateTime = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const newDate = date.getDate() + '-' + month + '-' + date.getFullYear();
    const showTime = date.getHours()
        + ':' + date.getMinutes()
        + ":" + date.getSeconds();
    return { date: newDate, time: showTime }
};